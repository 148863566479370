var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "recognition-page-container" }, [
    _c("div", { staticClass: "red-background-border" }),
    _c(
      "div",
      { staticClass: "recogniton-page-box" },
      [
        _c("PageButton", {
          attrs: {
            bottomCornerButtonList: _vm.bottomCornerButtonList,
            isLastStep: _vm.isLastStep,
          },
          on: {
            clickNext: _vm.nextPage,
            updateNumbers: function ($event) {
              return _vm.updateNumbers($event)
            },
          },
        }),
        _c("audio", { ref: "audioBox", attrs: { src: _vm.songUrl } }),
        _c("div", { staticClass: "recogniton-page-content layout-red-bgc" }, [
          _c(
            "div",
            {
              staticClass: "recognition-content-left",
              on: {
                click: function ($event) {
                  return _vm.ImgClick()
                },
              },
            },
            [
              _vm.isChange
                ? _c("img", {
                    staticClass: "santa-claus",
                    attrs: { src: _vm.backgroundImage, alt: "" },
                  })
                : _c("img", {
                    staticClass: "santa-claus",
                    attrs: { src: _vm.changeBgImg, alt: "" },
                  }),
            ]
          ),
          _c("div", { staticClass: "recogniton-content-right" }, [
            _c(
              "div",
              { staticClass: "recognize_container_content_right_border" },
              [
                _c(
                  "div",
                  { staticClass: "recognize_container_content_right_content" },
                  [
                    _c("div", { staticClass: "right-img" }, [
                      _c("img", { attrs: { src: _vm.rightImg, alt: "" } }),
                    ]),
                    _c("div", { staticClass: "englishName" }, [
                      _c("p", { staticClass: "pinyin font-pinyin-medium" }, [
                        _vm._v(" " + _vm._s(_vm.wordObj.pinyin) + " "),
                      ]),
                      _c("p", { staticClass: "hanzi font-hanzi-medium" }, [
                        _vm._v(" " + _vm._s(_vm.wordObj.hanzi) + " "),
                      ]),
                    ]),
                  ]
                ),
              ]
            ),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }