var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "liten-then-choose-words-game-container layout-border" },
    [
      _c("PageButton", {
        attrs: {
          bottomCornerButtonList: _vm.bottomCornerButtonList,
          isLastStep: _vm.isLastStep,
        },
        on: { clickNext: _vm.nextPage },
      }),
      _c(
        "div",
        { staticClass: "game-content" },
        [
          _c(
            "div",
            {
              staticClass: "speaker-wrapper",
              on: { click: _vm.playPinyinAudio },
            },
            [
              _c("AudioPlayer", {
                attrs: { isPlay: _vm.isStartPlaying, audioUrl: _vm.songUrl },
              }),
            ],
            1
          ),
          !_vm.isClick
            ? _c(
                "div",
                { staticClass: "left-container" },
                [
                  _c("div", { staticClass: "phone-top" }, [
                    _c("p", { staticClass: "left-pinyin" }, [
                      _vm._v(_vm._s(_vm.phonePinyin)),
                    ]),
                    _c("p", { staticClass: "left-hanzi" }, [
                      _vm._v(_vm._s(_vm.phoneHanzi)),
                    ]),
                    _c("img", {
                      attrs: { src: require("./delete.svg"), alt: "" },
                      on: { click: _vm.deleted },
                    }),
                  ]),
                  _vm._l(_vm.answerList, function (itemAnswer, indexAnswer) {
                    return _c(
                      "div",
                      { key: indexAnswer, staticClass: "phone-content" },
                      _vm._l(itemAnswer.allImgList, function (item, index) {
                        return _vm.answerData === indexAnswer
                          ? _c(
                              "div",
                              {
                                key: index,
                                staticClass: "img-item",
                                on: {
                                  mouseenter: function ($event) {
                                    return _vm.onEnter(index)
                                  },
                                  mouseleave: _vm.Leave,
                                  click: function ($event) {
                                    return _vm.clickNum(index)
                                  },
                                },
                              },
                              [
                                index === _vm.clickIndex &&
                                _vm.hanziArr.length > 0
                                  ? _c("img", {
                                      attrs: { src: item.bgImgDone, alt: "" },
                                    })
                                  : index === _vm.onMuoseIndex &&
                                    index != _vm.clickIndex
                                  ? _c("img", {
                                      attrs: { src: item.bgImgYellow, alt: "" },
                                    })
                                  : _c("img", {
                                      attrs: { src: item.bgImg, alt: "" },
                                    }),
                              ]
                            )
                          : _vm._e()
                      }),
                      0
                    )
                  }),
                  _c("div", { staticClass: "btn" }, [
                    _vm.phoneHanzi === _vm.answerList[_vm.answerData].answerInfo
                      ? _c("img", {
                          attrs: {
                            src: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-submit-green.svg`),
                            alt: "",
                          },
                          on: { click: _vm.changePhone },
                        })
                      : _c("img", {
                          attrs: {
                            src: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-submit-red.svg`),
                            alt: "",
                          },
                        }),
                  ]),
                ],
                2
              )
            : _vm._e(),
          _vm.isClick
            ? _c("div", { staticClass: "left-container-title" }, [
                _c("p", { staticClass: "left-pinyin" }, [
                  _vm._v(_vm._s(_vm.answerList[_vm.answerData].answerPin)),
                ]),
                _c("p", { staticClass: "left-hanzi" }, [
                  _vm._v(_vm._s(_vm.answerList[_vm.answerData].answerInfo)),
                ]),
              ])
            : _vm._e(),
          _c("div", { staticClass: "right-container" }, [
            _c("div", { staticClass: "right-img" }, [
              _c("img", {
                attrs: {
                  src: _vm.answerList[_vm.answerData].rightImg,
                  alt: "",
                },
              }),
            ]),
            _vm.phoneHanzi === _vm.answerList[_vm.answerData].answerInfo
              ? _c("div", { staticClass: "right-bottom" }, [
                  _c("p", { staticClass: "right-pinyin" }, [
                    _vm._v(_vm._s(_vm.answerList[_vm.answerData].answerPin)),
                  ]),
                  _c("p", { staticClass: "right-hanzi" }, [
                    _vm._v(_vm._s(_vm.answerList[_vm.answerData].answerInfo)),
                  ]),
                ])
              : _c("div", { staticClass: "right-bottom" }, [
                  _c("p", { staticClass: "right-hanzi" }, [_vm._v("?")]),
                ]),
          ]),
          _c("Star", {
            attrs: {
              totalStarNumber: _vm.answerList.length,
              currentIndex: _vm.answersfound,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }