<template>
  <div class="layout-border">
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :isLastStep="isLastStep"
      @clickNext="nextPage"
    ></PageButton>
    <div class="mainCont">
      <div class="game-content">
        <Star :totalStarNumber="optionList.length" :currentIndex="starNum" />
        <div class="title-area" v-if="titleInfo">
          <div class="title">
            <div class="title-pinyin pinyin font-pinyin-medium">
              {{ titleInfo.pinyin }}
            </div>
            <div class="title-hanzi font-hanzi-medium">
              {{ titleInfo.hanzi }}
            </div>
          </div>
        </div>
        <div class="background-img-area" :class="'lesson-' + lessonNum">
          <div class="img-container">
            <div class="img-area "
             v-for="(item,index) in optionList"
              :key="index">
              <img 
               :src="item.image" alt=""
               v-if="currentArr.indexOf(item.id)!=-1"
                />
                <img 
               :src="structureBgimg" alt=""
               v-else
                />
            </div>
              
          </div>
          <!-- <img
            class="bgIamge"
            :src="optionList[currentIndex].image"
          /> -->
          
        </div>
        <div class="bottonCont">
          <div class="redBackground"></div>
          <div class="bottonsWrapper">
            <div
              v-for="(item, index) in imgList[setp]"
              :key="index"
              class="content-words-two"
              :class="{ bgcolor: !item.nobgcolor }"
              @click="foundAnswer(item.id)"
              v-throttle
            >
              <span class="font-pinyin-medium pinyin">{{ item.pinying }}</span>
              <span class="font-hanzi-medium">{{ item.hanzi }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 失败和成功背景 -->
       <div
        class="checkGif"
        :class="{ checkGifWrong: !checkGif }"
        v-checkgif="checkGif"
        v-if="isShowCheckGif"
      ></div>
    </div>
     
      <!-- <button v-debounce="log">测试防抖</button> -->
  </div>
</template>
<script>
import { startConfetti, playCorrectSound } from "@/utils/tools";
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
import Star from "../Star";
import AudioPlayer from "@/components/Course/GamePage/AudioPlayer";

export default {
  components: {
    PageButton,
    Star,
    AudioPlayer,
  },
  props: {
    lessonNum: {
      type: Number,
      require: false,
    },
    optionList: {
      type: Array,
      require: true,
      default: () => [],
    },
    imgList: {
      type: Array,
      require: true,
      default: () => [],
    },
    backgroundSize: {
      type: String,
      require: false,
      default: "",
    },
    isHaveAudio: {
      type: Boolean,
      default: false,
    },
    titleInfo: {
      type: Object,
      require: false,
    },
  },
  data() {
    return {
      steps: 0,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "speak",
            startNumberRange: 1,
            endNumberRange: 100,
          },
        ],
      },
      structureBgimg: require("@/assets/img/16-GAMES/G75-structure-choose-5/l2-lesson-02/hanzi-empty.svg"),
      isLastStep: false,
      starNum: 0,
      currentIndex: 0,
      currentArr:[],
      socketInfo: {},
      audioSocketInfo: {},
      isStartPlaying: null,
      songUrl: this.imgList[0].audioSrc,
      isFadeOut: false,
      isFadeIn: false,
      checkGif: true,
      isShowCheckGif: false,
      setp:0
    };
  },
  mounted() {
    this.$bus.$on("RecognitionPinyinClick", ({ index }) => {
      this.foundAnswer(index, true);
    });
    this.$bus.$on("RecognitionPinyinAudio", () => {
      this.playPinyinAudio("socket", true);
    });
  },
  beforeDestroy() {
    this.$bus.$off("RecognitionPinyinClick");
    this.$bus.$off("RecognitionPinyinAudio");
  },
  watch: {
    socketInfo: {
      handler(value) {
        const data = {
          clickType: 20021,
          data: { value },
          text: "RecognitionPinyinGame点击socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    audioSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2002101,
          data: { value },
          text: "RecognitionPinyinGame点击小喇叭 socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  methods: {
    playPinyinAudio(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.audioSocketInfo = {
          value: Math.random(),
        };
      }
      this.isStartPlaying = Math.random();
    },
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
    // log(){
    //   console.log("防抖");
    // },
    foundAnswer(index, isFromSocket = false) {
      if (this.starNum >= this.optionList.length) {
        return;
      }
      if (!isFromSocket) {
        this.socketInfo = {
          index,
          value: Math.random(),
        };
      }
    //   throttle((e) => {
    //   console.log(123456)
    // }, 1000)(),
      console.log(index);
      if (this.optionList[this.currentIndex].id === index) {
        this.currentArr.push(index)
        this.currentArr=[...new Set(this.currentArr)]
        this.isShowCheckGif = true;
        this.checkGif = true;
        playCorrectSound(true, false);
        this.starNum++;
        setTimeout(() => {
          this.isShowCheckGif = false;
          this.setp++
        }, 1000);
          if (this.currentIndex + 1 < this.optionList.length) {
            //first trigger the fadeout
            // this.isFadeOut = true;
              this.currentIndex++;
              // this.isFadeIn = true;
              // this.isFadeOut = false;
              // setTimeout(()=>{
              //   this.isFadeIn = false;
              // },500)  
            // this.songUrl = this.imgList[this.currentIndex+1].audioSrc; 
          } else {
            playCorrectSound();
            startConfetti();
            this.isLastStep = true;
          }
      } else {
        playCorrectSound(false);
        this.isShowCheckGif = true;
        this.checkGif = false;
        setTimeout(() => {
          this.isShowCheckGif = false;
        }, 1000);
      }
    },
    // test:throttle((e) => {
    //   console.log(123)
    // }, 1000),
  
  
  },
};
</script>
<style lang="scss" scoped>
.bottonCont {
  bottom: 0;
  width: 80%;
  height: 150px;
  position: absolute;
  display: flex;
  justify-content: center;
  margin-left: 11%;

  .redBackground {
    width: 70%;
    height: 70%;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    background-color: #d87065;
    position: absolute;
    bottom: 0;
  }
  .bottonsWrapper {
    min-width: 300px;
    display: flex;
    bottom: 0;
    height: -moz-fit-content;
    justify-content: space-around;
    height: 100%;
    padding: 20px;
    position: absolute;
    padding: 10px 30px;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    background-color: #ffffff;
    align-items: center;
  }
}
.bgcolor {
  background-color: #F7F4E0;
  border-bottom: 10px solid #CFC8B0;
  border-top: 10px solid #FDFCF0;
  padding: 10px 10px;
  box-sizing: border-box;
  // max-width: 150px;
}  
.bgcolor:hover {
  background-color: #F4D737;
  border-bottom: 10px solid #D1B726;
  border-top: 10px solid #FCED9B;
}
.content-words-two {
  // max-width: 150px;
  // width: 200px;
  min-width: 100px;
  min-height: 100px;
  z-index: 1;
  border-radius: 15px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  cursor: pointer;
  span {
    color: #0A2550;
    font-size: 25px;
  }
  // span:nth-child(2) {
  //   color: #0A2550;
  //   font-size: 30px;
  // }
  img {
    max-width: 80%;
  }

}
// .content-words-two:hover {
//   min-width: 100px;
//   min-height: 100px;
//   border-radius: 15px;
//   border-bottom: 0px;
//   display: flex;
//   flex-flow: column;
//   justify-content: center;
//   align-items: center;
//   span {
//     color: #fff;
//     font-size: 25px;
//   }
//   span:nth-child(2) {
//     color: #fff;
//     font-size: 30px;
//   }
// }

.objectDisplay {
  position: absolute;
  height: 100%;
  //  bottom: 16%;
  //  left: 25%;
  width: 100%;
  z-index: 9999;
}
.soundIcon {
  position: absolute;
  width: 10%;
  top: 45%;
  left: 45%;
  cursor: pointer;
}
.speaker-wrapper {
  position: absolute;
  top: calc(55% - 35px);
  left: calc(50% - 35px);
  width: 70px;
  height: 70px;
  background-color: #cd4c3f;
  border-radius: 50%;
  text-align: center;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
  .speaker-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
.toneImg {
  width: 20% !important;
  left: 40% !important;
  top: -3%;
}
.mainCont {
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 48px;
  position: relative;
}
.blueBg {
  background: #abeaff !important;
}
.game-content {
  text-align: -webkit-center;
  position: absolute;
  height: 100%;
  width: 100%;
  background: url("./background-structure.svg") no-repeat;
  background-size: cover;
  border-radius: 48px;
  .title-area {
    z-index: 9;
    position: absolute;
    top: 0;
    left: 0;
    top: 10%;
    padding:20px 30px;
    width: auto;
    height: auto;
    background: #cd4c3f;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 28px;
    border-bottom-right-radius: 28px;
    color: #fff;
    .title {
      .title-pinyin,
      .title-hanzi {
        text-align: center;
      }
    }
  }
}
.background-img-area {
  position: relative;
  width: -moz-fit-content;
  width: 100%;
  height: 100%;
  // top:30%;
  // margin-top: 30%;
  justify-content: center;
  align-items: center;
  z-index: 0;
  // border-radius: 10%;
  display: flex;
  .img-container{
  margin-top:-10%;
  background: url("./background.svg") no-repeat;
  background-size: 100% 100%;
  width: 70%;
  height: 25%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding:10px;
  .img-area{
    width: 10%;
    img{
        width:100%
      }
  }
 
  }
  .bgIamge {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 48px;

    &.isCover {
      object-fit: cover !important;
    }
  }
}
.lesson-7 {
  .bgIamge {
    width: 60%;
    height: 60%;
    margin-top: 10%;
  }
}
.images {
  position: absolute;
  height: 100%;
  top: 0;
  width: 100%;
  border-radius: 10%;
  img {
    position: relative;
    height: 100%;
    text-align: -webkit-center;
  }
}
</style>
