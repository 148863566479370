var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "layout-border" },
    [
      _c("PageButton", {
        attrs: {
          bottomCornerButtonList: _vm.bottomCornerButtonList,
          isLastStep: _vm.isLastStep,
        },
        on: { clickNext: _vm.nextPage },
      }),
      _c("div", { staticClass: "mainCont" }, [
        _c(
          "div",
          { staticClass: "game-content" },
          [
            _c("Star", {
              attrs: {
                totalStarNumber: _vm.optionList.length,
                currentIndex: _vm.starNum,
              },
            }),
            _vm.titleInfo
              ? _c("div", { staticClass: "title-area" }, [
                  _c("div", { staticClass: "title" }, [
                    _c(
                      "div",
                      { staticClass: "title-pinyin pinyin font-pinyin-medium" },
                      [_vm._v(" " + _vm._s(_vm.titleInfo.pinyin) + " ")]
                    ),
                    _c(
                      "div",
                      { staticClass: "title-hanzi font-hanzi-medium" },
                      [_vm._v(" " + _vm._s(_vm.titleInfo.hanzi) + " ")]
                    ),
                  ]),
                ])
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "background-img-area",
                class: "lesson-" + _vm.lessonNum,
              },
              [
                _c(
                  "div",
                  { staticClass: "img-container" },
                  _vm._l(_vm.optionList, function (item, index) {
                    return _c("div", { key: index, staticClass: "img-area" }, [
                      _vm.currentArr.indexOf(item.id) != -1
                        ? _c("img", { attrs: { src: item.image, alt: "" } })
                        : _c("img", {
                            attrs: { src: _vm.structureBgimg, alt: "" },
                          }),
                    ])
                  }),
                  0
                ),
              ]
            ),
            _c("div", { staticClass: "bottonCont" }, [
              _c("div", { staticClass: "redBackground" }),
              _c(
                "div",
                { staticClass: "bottonsWrapper" },
                _vm._l(_vm.imgList[_vm.setp], function (item, index) {
                  return _c(
                    "div",
                    {
                      directives: [{ name: "throttle", rawName: "v-throttle" }],
                      key: index,
                      staticClass: "content-words-two",
                      class: { bgcolor: !item.nobgcolor },
                      on: {
                        click: function ($event) {
                          return _vm.foundAnswer(item.id)
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "font-pinyin-medium pinyin" }, [
                        _vm._v(_vm._s(item.pinying)),
                      ]),
                      _c("span", { staticClass: "font-hanzi-medium" }, [
                        _vm._v(_vm._s(item.hanzi)),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]),
          ],
          1
        ),
        _vm.isShowCheckGif
          ? _c("div", {
              directives: [
                {
                  name: "checkgif",
                  rawName: "v-checkgif",
                  value: _vm.checkGif,
                  expression: "checkGif",
                },
              ],
              staticClass: "checkGif",
              class: { checkGifWrong: !_vm.checkGif },
            })
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }