<template>
  <div class="liten-then-choose-words-game-container layout-border">
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :isLastStep="isLastStep"
      @clickNext="nextPage"
    ></PageButton>
    <div class="game-content">
      <div class="speaker-wrapper" @click="playPinyinAudio" >
        <AudioPlayer :isPlay="isStartPlaying" :audioUrl="songUrl" />
      </div>
      <!-- 手机区域 -->
      <div class="left-container" v-if="!isClick">
        <div class="phone-top">
          <p class="left-pinyin">{{ phonePinyin }}</p>
          <p class="left-hanzi">{{ phoneHanzi }}</p>
          <img @click="deleted" src="./delete.svg" alt="" />
        </div>
        <div class="phone-content" v-for="(itemAnswer, indexAnswer) in answerList" :key="indexAnswer">
          <div
            v-if="answerData === indexAnswer"
            class="img-item"
            v-for="(item, index) in itemAnswer.allImgList"
            :key="index"
            @mouseenter="onEnter(index)"
            @mouseleave="Leave"
            @click="clickNum(index)"
          >
            <img
              v-if="index === clickIndex && hanziArr.length > 0"
              :src="item.bgImgDone"
              alt=""
            />
            <img
              v-else-if="index === onMuoseIndex && index != clickIndex"
              :src="item.bgImgYellow"
              alt=""
            />
            <img v-else :src="item.bgImg" alt="" />
          </div>
        </div>
        <!-- 确认按钮 -->
        <div class="btn">
          <img
           v-if="phoneHanzi === answerList[answerData].answerInfo"
            @click="changePhone"
            :src="
              require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-submit-green.svg`)
            "
            alt=""
          />
          <img
            v-else
            :src="
              require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-submit-red.svg`)
            "
            alt=""
          />
        </div>
      </div>

      <!-- 点击确认 -->
      <div class="left-container-title" v-if="isClick">
        <p class="left-pinyin">{{answerList[answerData].answerPin}}</p>
        <p class="left-hanzi">{{answerList[answerData].answerInfo}}</p>
      </div>

      <!-- 右边图片区域 -->
      <div class="right-container">
        <div class="right-img">
          <img :src="answerList[answerData].rightImg" alt="" />
        </div>
        <div class="right-bottom" v-if="phoneHanzi === answerList[answerData].answerInfo">
          <p class="right-pinyin">{{answerList[answerData].answerPin}}</p>
          <p class="right-hanzi">{{answerList[answerData].answerInfo}}</p>
        </div>
        <div class="right-bottom" v-else>
          <p class="right-hanzi">?</p>
        </div>
      </div>
      <Star :totalStarNumber="answerList.length" :currentIndex="answersfound" />
    </div>
  </div>
</template>

<script>
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
import AudioPlayer from "@/components/Course/GamePage/AudioPlayer";
import { startConfetti, playCorrectSound } from "@/utils/tools.js";
import Star from "../Star";
export default {
  props: {
    answerList: {
      type: Array,
      require: true,
    },

    titleInfo: {
      type: Object,
      require: false,
    },
  },
  components: {
    PageButton,
    AudioPlayer,
    Star,
  },

  data() {
    return {
      answerData: 0,
      answersfound: 0,
      isStartPlaying: null,
      lockIndex: 0,
      insertIndex: 0,
      chooseImgIndex: null,
      numBers: 0,
      onMuoseIndex: null,
      currentIndex: 0,
      phonePinyin: "",
      phoneHanzi: "",
      currentStarIndex: 0,
      clickIndex: null,
      pinyinArr: [],
      hanziArr: [],
      IdArr: [],
      timer: null,
      isClick: false,
      songUrl: this.audioSrc,
      isLastStep: false,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "look",
            startNumberRange: 0,
            endNumberRange: 100,
          },
        ],
      },
    };
  },
  created() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    playPinyinAudio(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.audioSocketInfo = {
          value: Math.random(),
        };
      }
      this.isStartPlaying = Math.random();
    },
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
    onEnter(index) {
      this.onMuoseIndex = index;
    },
    Leave() {
      this.onMuoseIndex = null;
    },
    clickNum(index) {
      this.clickIndex = index;
      if (
        this.IdArr.indexOf(this.answerList[this.answerData].allImgList[index].id) === -1 &&
        this.answerList[this.answerData].allImgList[index].id
      ) {
        this.IdArr.push(this.answerList[this.answerData].allImgList[index].id);
      }
      console.log(this.IdArr);
      if (
        this.answerList[this.answerData].allImgList[index].pinyin &&
        this.pinyinArr.indexOf(this.answerList[this.answerData].allImgList[index].pinyin) == -1
      ) {
        this.phonePinyin += this.answerList[this.answerData].allImgList[index].pinyin;
        this.phoneHanzi += this.answerList[this.answerData].allImgList[index].hanzi;
        if (this.phoneHanzi === this.answerInfo) playCorrectSound(true, false);
      }
      if (
        this.pinyinArr.indexOf(this.answerList[this.answerData].allImgList[index].pinyin) === -1 &&
        this.answerList[this.answerData].allImgList[index].pinyin
      ) {
        this.pinyinArr.push(this.answerList[this.answerData].allImgList[index].pinyin);
      }
      if (
        this.hanziArr.indexOf(this.answerList[this.answerData].allImgList[index].hanzi) === -1 &&
        this.answerList[this.answerData].allImgList[index].hanzi
      ) {
        this.hanziArr.push(this.answerList[this.answerData].allImgList[index].hanzi);
        console.log(123);
      }
      console.log(this.pinyinArr);
    },
    deleted() {
      this.phonePinyin = this.phonePinyin.replace(
        this.pinyinArr[this.pinyinArr.length - 1],
        ""
      );
      this.phoneHanzi = this.phoneHanzi.replace(
        this.hanziArr[this.hanziArr.length - 1],
        ""
      );
      this.pinyinArr.pop();
      this.hanziArr.pop();
      this.IdArr.pop();
    },
    changePhone() {
      clearTimeout(this.timer);
      this.answersfound += 1
      this.isClick = true;
      playCorrectSound();
      startConfetti();
      this.isLastStep = true;
      this.timer = setTimeout(()=>{
        this.isLastStep = false;
        this.isClick = false;
        this.answerData += 1
        this.phonePinyin = ""  
        this.phoneHanzi = ""
      },1000);
    },
  },
};
</script>
<style lang="scss" scoped>
.liten-then-choose-words-game-container {
  .game-content {
    width: 100%;
    height: 100%;
    position: relative;
    background: url("../../../../assets/img/16-GAMES/G65-stories-3-cards/level1-background.svg");
    border-radius: 58px;
    .speaker-wrapper {
      position: absolute;
      top: 76%;
      right: 22%;
      width: 60px;
      height: 65px;
      background-color: #cd4c3f;
      border-radius: 50%;
      text-align: center;
      &:hover {
        opacity: 0.8;
        cursor: pointer;
      }
      .speaker-img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    .left-container-title {
      position: absolute;
      left: 10%;
      top: 10%;
      width: 35%;
      height: 80%;
      background: #112e5d;
      border: 15px solid #224e96;
      border-radius: 28px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: white;
      .left-pinyin {
        font-size: 18px;
      }
      .left-hanzi {
        font-size: 50px;
      }
    }
    .left-container {
      position: absolute;
      left: 10%;
      top: 10%;
      width: 35%;
      height: 80%;
      background: #ffffff;
      border: 15px solid #224e96;
      border-radius: 28px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .phone-top {
        width: 80%;
        height: 50px;
        // background: blue;
        border-bottom: 2px solid #cfcecb;
        margin-top: -5%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        .left-hanzi {
          font-size: 28px;
        }
        img {
          position: absolute;
          right: 0;
          cursor: pointer;
          &:hover {
            opacity: 0.5;
          }
        }
      }
      .phone-content {
        width: 80%;
        // height: 380px;
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        .img-item {
          width: 30%;
        }
        img {
          width: 100%;
          // display:block;
        }
      }
      .btn {
        width: 30%;
        cursor: pointer;
        margin-top: 10px;
        &:hover {
          opacity: 0.7;
        }
        img {
          width: 100%;
        }
      }
    }
    .right-container {
      background: #ce524c;
      position: absolute;
      width: 30%;
      height: 40%;
      top: 27%;
      right: 10%;
      border-radius: 28px;
      .right-img {
        border-radius: 28px;
        margin-top: -2px;
        background: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 100%;
          border-radius: 20px;
        }
      }
      .right-bottom {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        margin-top: 5%;
        .right-hanzi {
          font-size: 28px;
        }
      }
    }
  }
}
</style>
