<template>
  <div class="recognition-page-container">
    <div class="red-background-border"></div>
    <div class="recogniton-page-box">
      <PageButton
        :bottomCornerButtonList="bottomCornerButtonList"
        :isLastStep="isLastStep"
        @clickNext="nextPage"
        @updateNumbers="updateNumbers($event)"
      ></PageButton>
      <audio :src="songUrl" ref="audioBox"></audio>

      <div class="recogniton-page-content layout-red-bgc">
        <!-- 左边图片 left image -->
        <div class="recognition-content-left" @click="ImgClick()">
          <img v-if="isChange" class="santa-claus " :src="backgroundImage" alt="" />
          <img v-else class="santa-claus" :src="changeBgImg" alt="" />
          
        </div>

        <!-- 右边文字 right words-->
        <div class="recogniton-content-right">
          <div class="recognize_container_content_right_border">
            <div class="recognize_container_content_right_content">
              <div
                class="right-img"
              >
               <img :src="rightImg" alt="">
              </div>
              <div
                class="englishName"
              >
                <p class="pinyin font-pinyin-medium"
                >
                  {{ wordObj.pinyin }}
                </p>
                <p class="hanzi font-hanzi-medium">
                  {{wordObj.hanzi}}
                </p>
              </div>
              
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
import AudioPlayer from "@/components/Course/GamePage/AudioPlayer";

export default {
  components: {
    PageButton,
    AudioPlayer,
  },
  props: {
    wordObj: {
      type: Object,
      require: true,
      default: () => {},
    },
    backgroundImage: {
      type: String,
      require: true,
    },
    changeBgImg: {
      type: String,
      require: true,
    },
    rightImg: {
      type: String,
      require: true,
    },
    isTonePage: {
      type: Boolean,
      default: false,
    },
    pySize: {
      type: String,
      require: false,
      default: "",
    },
  },
  data() {
    return {
      audioSocketInfo: {},
      songUrl: null,
      isClickAudio: false,
      clickNum: 0,
      isHover: false,
      isChange:true,
      // clickedVolumePlay: 0,
      isStartPlaying: null,
      isLastStep: true,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "next",
            startNumberRange: 1,
            endNumberRange: 1,
            clickGoToNextPage: true,
          },
        ],
      },
    };
  },
  watch: {
    // 发送
    audioSocketInfo: {
      handler(value) {
        const data = {
          clickType: 20002,
          data: { value },
          text: "测试RecognitionPage组件内点击事件socket",
        };
        this.$bus.$emit("kids_webSocket_sendInfo", data);
      },
      deep: true,
    },
  },
  mounted() {
    // this.$bus.$on("clickRecognitionPage", () => {
    //   this.activeClick("socket", true);
    // });
    this.$bus.$on("hoverRecognitionPage", (val) => {
      this.isClickAudio = !this.isClickAudio;
    });
  },
  beforeDestroy() {
    this.$bus.$off("clickRecognitionPage");
  },
  methods: {
    // activeClick(socket, isFromSocket = false) {
    //   if (!isFromSocket) {
    //     this.audioSocketInfo = {
    //       value: Math.random(),
    //     };
    //   }
    //   this.isStartPlaying = Math.random();

    //   if (this.isTonePage) {
    //     console.log("+++++++++++");
    //     this.syncPlayAudio(this.wordObj.songUrlList);
    //   } else {
    //     this.songUrl = this.wordObj.songUrl;
    //   }
    // },
    ImgClick(){
      this.isChange=!this.isChange
      console.log(this.isChange);
    },
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
    updateNumbers(value) {
      this.numBers = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.recognition-page-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  .recogniton-page-box {
    // display: none;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    background: #fff;
    border-radius: 58px;
    z-index: 2;
    position: relative;
    .recogniton-page-content {
      background: #f7f4e0;
      border-radius: 58px;
      position: relative;
      z-index: 3;

      .recognition-content-left {
        width: 50%;
        height: 100%;
        position: absolute;
        img {
          cursor: pointer;
          position: absolute;
          right: 0;
          top: 20%;
          width: 80%;
        }
        .santa-claus {
          top: 15%;
        }
      }
      .recogniton-content-right {
        position: absolute;
        width: 50%;
        height: 100%;
        right: 0;
        .recognize_container_content_right_border {
          margin-top: 10px;
          margin-left: 10px;
          width: calc(70% - 10px);
          height: calc(60% - 10px);
          position: absolute;
          left: 8%;
          top: 13%;
          background: #224e96;
          border-radius: 40px;
          padding: 20px 10px;
          .recognize_container_content_right_content {
            color: #000;
            text-align: center;
            position: absolute;
            width: 100%;
            height: 100%;
            background: #fff;
            border-radius: 40px;
            top: -10px;
            left: -10px;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            cursor: default;

            .englishName {
              max-width: 70%;
            }
            .font-hanzi-large {
              font-size: 2.5em;
              margin-bottom: 30px;
            }
            .recognize_container_content_right_content_top {
              width: 50px;
              height: 50px;
              background: #ce4c3f;
              border-radius: 50%;

              &.volume-button {
                cursor: pointer;
              }
              div {
                width: 100%;
                height: 100%;
              }
            }
            .recognize_container_content_right_content_top:hover {
              width: 50px;
              height: 50px;
              background: #b5392c;
              border-radius: 50%;
              img {
                border-radius: 50%;
                background: #b5392c;
                width: 100%;
              }
            }
            .tone_content {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              width: 50%;
              p {
                width: 50%;
                margin-bottom: 8px;
              }
            }
          }
        }
      }
    }
  }
  .right-img {
    img{
      width: 100%;
    }
  }
  .teacher-hover {
    img {
      background: #b5392c !important;
      border-radius: 50%;
    }
  }
}
</style>
