var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "recognition-page-container" }, [
    _c("div", { staticClass: "red-background-border" }),
    _c(
      "div",
      { staticClass: "recogniton-page-box" },
      [
        _c("PageButton", {
          attrs: {
            bottomCornerButtonList: _vm.bottomCornerButtonList,
            isLastStep: _vm.isLastStep,
          },
          on: {
            clickNext: _vm.nextPage,
            updateNumbers: function ($event) {
              return _vm.updateNumbers($event)
            },
          },
        }),
        _c("audio", { ref: "audioBox", attrs: { src: _vm.songUrl } }),
        _c(
          "div",
          { staticClass: "recogniton-page-content layout-red-bgc" },
          [
            _c("Title", { attrs: { titleInfo: _vm.titleInfo } }),
            _c(
              "div",
              {
                staticClass: "recognition-content-left",
                on: {
                  click: function ($event) {
                    return _vm.changeImg()
                  },
                },
              },
              [
                !_vm.showImg
                  ? _c("img", {
                      staticClass: "santa-claus",
                      attrs: { src: _vm.backgroundImage, alt: "" },
                    })
                  : _c("img", {
                      staticClass: "santa-claus",
                      attrs: { src: _vm.changeBgImg, alt: "" },
                    }),
              ]
            ),
            _c("div", { staticClass: "recogniton-content-right" }, [
              _c(
                "div",
                { staticClass: "recognize_container_content_right_border" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "recognize_container_content_right_content",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "recognize_container_content_right_content_top volume-button",
                          class: { "teacher-hover": _vm.isClickAudio },
                        },
                        [
                          _c(
                            "div",
                            {
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.activeClick("socket")
                                },
                              },
                            },
                            [
                              _c("AudioPlayer", {
                                attrs: {
                                  isPlay: _vm.isStartPlaying,
                                  audioUrl: _vm.songUrl,
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm.wordObj.pinName != "" && !_vm.wordObj.toneContent
                        ? _c("div", { staticClass: "redColor" }, [
                            _c(
                              "p",
                              { staticClass: "pinyin font-pinyin-xlarge" },
                              [_vm._v(" " + _vm._s(_vm.wordObj.pinName) + " ")]
                            ),
                            _c(
                              "p",
                              {
                                staticClass: "font-hanzi-xlarge",
                                attrs: { id: "face" },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.wordObj.chineseName) + " "
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm.wordObj.pinName == "" && !_vm.wordObj.toneContent
                        ? _c("div", { staticClass: "englishName" })
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          directives: [
                            { name: "throttle", rawName: "v-throttle" },
                          ],
                          staticClass: "btn",
                          on: {
                            click: function ($event) {
                              return _vm.btnClick()
                            },
                          },
                        },
                        [
                          _c("p", { staticClass: "btnPinyin" }, [
                            _vm._v(_vm._s(_vm.wordObj.pinyin)),
                          ]),
                          _c("p", { staticClass: "btnHanzi" }, [
                            _vm._v(_vm._s(_vm.wordObj.hanzi)),
                          ]),
                        ]
                      ),
                      _vm.wordObj.toneContent &&
                      _vm.wordObj.toneContent.length > 0
                        ? _c(
                            "div",
                            { staticClass: "tone_content" },
                            _vm._l(
                              _vm.wordObj.toneContent,
                              function (item, index) {
                                return _c(
                                  "p",
                                  {
                                    key: index,
                                    staticClass: "pinyin",
                                    class: [
                                      _vm.pySize == "xlarge"
                                        ? "font-hanzi-xlarge"
                                        : "font-hanzi-large",
                                    ],
                                  },
                                  [_vm._v(" " + _vm._s(item) + " ")]
                                )
                              }
                            ),
                            0
                          )
                        : _vm._e(),
                    ]
                  ),
                ]
              ),
            ]),
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isShowMask,
                expression: "isShowMask",
              },
            ],
            class: [_vm.isMask ? "mask-area fadeIn" : "mask-area fadeOut"],
            on: { click: _vm.mask },
          },
          [
            _c("div", { staticClass: "mask-item" }, [
              _c("div", { staticClass: "mask-img" }, [
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.maskImg,
                      expression: "maskImg",
                    },
                  ],
                  attrs: { src: _vm.maskImg, alt: "" },
                }),
              ]),
            ]),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }