<template>
  <div class="drag-game layout-border">
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :isLastStep="isLastStep"
      @clickNext="nextPage"
    ></PageButton>
    <div class="drag-game-content" ref="moveGameBox">
      <div class="contentImg">
        <img v-show="step === 0" src="@/assets/img/16-GAMES/G65-stories-3-cards/l2-lesson-02/mask.svg" alt="">
        <img v-show="step != 0" src="@/assets/img/16-GAMES/G65-stories-3-cards/l2-lesson-02/background.svg" alt="">
      </div>
      <SceneSwitchThumbnails
        :buttons="buttonList"
        :numBers="numBers"
        @changeTheNumbers="changeTheNumbers"
      >
      </SceneSwitchThumbnails>
       <div class="dogImg" :class="'step-' + step">
          <img v-if="step === 0" src="@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/dog.svg" alt="">
          <img v-if="step != 0" src="@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/dog-01.svg" alt="">
        </div>
      <div class="picture-envelope" v-for="(itemBox, indexBox) in allImgList" :key="indexBox" v-if="numBers === (indexBox + 1)">
        <div class="content-picture-switching">
          <div
            
            class="lesson-one"
            v-for="(item, index) in itemBox.imgList"
            :key="index"
            :class="
              ({
                notShow: showIndex === item.key,
              },
              'lesson' + item.key)
            "
            draggable="true"
            @dragstart.stop="drag($event, item)"
            @drop.stop="drop($event)"
            @dragend="handleDragend"
          >
            <img
              class="switchImg"
              :src="item.bgImg"
              alt=""
              :class="
                ({
                  dispear: item.isDispear,
                  'drag-img-envelope-4': dragNum === 4,
                },
                'switchImg' + item.key)
              "
            />
            <div class="pinYin">
              <span class="title-pinyin pinyin font-pinyin-medium">{{item.pinYin}}</span>
              <span class="title-hanzi font-hanzi-medium">{{item.hanZi}}</span>
            </div>
            <div class="audioUrl" @click.stop="playPinyinAudio(item)">
              <AudioPlayer :isPlay="item.isStartPlaying" :audioUrl="songUrl" class="audioUrl_img"/>
            </div>
          </div>
        </div>
      </div>
      <div class="picture-mailbox">
        <div class="content-picture-switching">
          <div
            class="lesson-one"
            v-for="(item, index) in mailboxList"
            :key="index"
            draggable="true"
            @drop.stop="drop($event, item)"
            @dragover.stop="allowDrop($event)"
          >
            <span :class="{ 'drag-img-mailbox-4': dragNum === 4,'xuXian': !item.isDispear}"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { startConfetti, playCorrectSound } from "@/utils/tools";
import SceneSwitchThumbnails from "@/components/Course/CoursePage/StorySwitcher/sceneSwitchThumbnails.vue";
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
import AudioPlayer from "@/components/Course/GamePage/AudioPlayer";
export default {
  props: {
    buttonList: {
      type: Array,
      require: true,
      default: () => [],
    },
    allImgList: {
      type: Array,
      require: true,
    },
    mailboxList: {
      type: Array,
      require: true,
    },
    durationArr: {
      type: Array,
      require: true,
    },
    dragNum: {
      type: Number,
      require: true,
    },
  },
  components: {
    PageButton,
    SceneSwitchThumbnails,
    AudioPlayer,
  },
  data() {
    return {
      numBers: 1,
      songUrl: null,
      lock: false,
      ramdomIndex: null,
      currentDom: null,
      currentKey: null,
      originDom: null,
      scrollDom: null,
      scrollWidth: null,
      scrollSpeed: 2,
      finishNum: 0,
      distance: 0,
      step: 0,
      dragSocketInfo: {},
      checkInfo: {},
      success_audio_sound: require("@/assets/audio/correct_answer.mp3"),
      isLastStep: false,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "look",
            startNumberRange: 0,
            endNumberRange: 100,
          },
        ],
      },
      checkDragValue: false,
      dragSocketInfoValue: false,
      showIndex: null,
    };
  },
  mounted() {
    this.$bus.$on("clickRandomBtn", (value) => {
      this.$emit("socketRandom", value);
      this.bottomCornerButtonList.buttons[0].type = "look";
      this.lock = true;
    });
    this.$bus.$on("dragEnvelope", ({ currentKey }) => {
      this.originDom = document.getElementsByClassName(
        `lesson${currentKey}`
      )[0];
      this.currentDom = document.getElementsByClassName(
        `switchImg${currentKey}`
      )[0];
      this.currentKey = currentKey;
      this.dragSocketInfoValue = true;
    });
    this.$bus.$on("checkDrag", ({ item }) => {
      let event = {};
      event.target = document.getElementsByClassName(`word${item.value}`)[0];
      this.drop(event, item);
      this.checkDragValue = true;
    });
  },
  beforeDestroy() {
    this.$bus.$off("clickRandomBtn");
    this.$bus.$off("dragEnvelope");
    this.$bus.$off("checkDrag");
    clearTimeout(this.timer);
  },
  watch: {
    finishNum(value) {
      if (value === this.dragNum) {
        this.isLastStep = true;
        this.handleClickBgImg();
        startConfetti();
        playCorrectSound();
      }
    },
    dragSocketInfo: {
      handler(value) {
        if (!this.dragSocketInfoValue) {
          const data = {
            clickType: 2001002,
            data: { value },
            text: "DragGameBtn drag点击socket",
          };
          this.sendSocketInfo = { ...data };
          this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
        }
      },
      deep: true,
    },
    checkInfo: {
      handler(value) {
        if (!this.checkDragValue) {
          const data = {
            clickType: 2001003,
            data: { value },
            text: "DragGameBtn check点击socket",
          };
          this.sendSocketInfo = { ...data };
          this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
        }
      },
      deep: true,
    },
  },
  methods: {
    handleClickBgImg() {
      if (this.step >= this.durationArr.length + 1) {
        return;
      }
      this.step++;
      if (this.step > 0) {
        this.ControlScroll(this.step);
      }
      if (this.step >= this.durationArr.length + 1) {
        return;
      }
    },
    ControlScroll(step) {
      this.scrollMove(this.durationArr[step]);
    },
    scrollMove(num) {
      this.scrollDom = this.$refs.moveGameBox;
      this.scrollWidth = this.scrollDom.scrollWidth;
      const speed = this.scrollSpeed;
      let timer = setInterval(() => {
        this.distance += speed;
        this.scrollDom.scrollLeft = this.distance;
        if (this.distance / this.scrollWidth >= num) {
          clearInterval(timer);
        }
      }, 10);
    },
    changeTheNumbers(index, isFromSocket = false) {
      if (!isFromSocket) {
        this.clickMenuSocketInfo = {
          index,
          value: Math.random(),
        };
      }
      this.numBers = index;
      this.currentIndex = index - 1;
      this.chooseImgIndex = null;
      this.initialization()
    },
    initialization() {
      this.step = 0
      this.finishNum = 0
      for(let i = 0 ;i < 4;i ++) {
        document.getElementsByClassName('drag-img-mailbox-4')[i].innerHTML = " "
      }
    },
    playPinyinAudio(type) {
      type.isStartPlaying = Math.random();
      this.songUrl = type.audioSrc;
    },
    handleDragend() {
      this.showIndex = null;
    },
    changeOrder() {
      if (this.lock) {
        return;
      }
      this.$emit("randomOrder");
      const data = {
        clickType: 2001001,
        data: { value: this.allImgList },
        text: "DragGameBtn点击socket",
      };
      this.sendSocketInfo = { ...data };
      this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      this.bottomCornerButtonList.buttons[0].type = "look";
      this.lock = true;
    },
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
    drag(event, item) {
      this.showIndex = item.key;
      this.dragSocketInfoValue = false;
      this.originDom = event.currentTarget.parentNode;
      this.currentDom = event.currentTarget;
      this.currentKey = item.key;
      this.dragSocketInfo = {
        currentKey: this.currentKey,
        ramdomIndex: Math.random(),
      };
    },
    drop(event, item) {
      this.checkDragValue = false;
      this.checkInfo = {
        event: {
          target: event.target,
        },
        ramdomIndex: Math.random(),
        item,
      };
      event.target.appendChild(this.currentDom); // 传入信封 
      if (this.currentKey === item.value) {
        this.finishNum++;
        playCorrectSound(true, false);
      } else {
        setTimeout(() => {
          event.target.removeChild(this.currentDom); // 传入信封
          this.originDom.appendChild(this.currentDom);
          event.target.parentElement.className += " shake";
          playCorrectSound(false);
          setTimeout(() => {
            event.target.parentElement.className =
              event.target.parentElement.className.replace("shake", "");
          }, 1000);
        }, 200);
      }
    },

    allowDrop(event) {
      event.preventDefault();
    },
    startConfetti() {
      var n = new Audio(this.success_audio_sound);
      n.src = this.success_audio_sound;
      n.play();
      var myCanvas = document.getElementById("confettiCanvas");
      var myConfetti = confetti.create(myCanvas, {
        resize: true,
        useWorker: true,
      });
      var end = Date.now() + 0.5 * 1000;

      // go Buckeyes!
      var colors = ["#ce4c3f", "#224e96", "#ffffff"];

      (function frame() {
        myConfetti({
          particleCount: 2,
          spread: 55,
          colors: colors,
        });
        if (Date.now() < end) {
          requestAnimationFrame(frame);
        }
      })();
    },
  },
};
</script>
<style scoped lang="scss">
.switchImg {
  -webkit-user-drag: auto !important;
}

.drag-game {
  .drag-game-content {
    width: 100%;
    height: 100%;
    border-radius: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    position: relative;
    .contentImg{
      height: 100%;
      width: 100%;
      img{
        height: 100%;
        width: 100%;
        border-radius: 58px;
      }
    }
    .dogImg{
      position: absolute;
      top: 60%;
      left: 10%;
      transition: all 2s;
    }
    .step-1 {
      position: absolute;
      top: 60%;
      left: 75% !important;
    }
    .picture-envelope {
      position: absolute;
      top: 0;
      width: 100%;
      height: 60%;
      display: flex;
      justify-content: center;
      .content-picture-switching {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        .lesson-one {
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            display: inline-block;
            width: 100%;
          }
          .audioUrl{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top:-20px;
            .audioUrl_img{
              width: 40px;
              height: 40px;
              border-radius: 20px;
              background-color: #CD4C3F;
              
            }
          }
          .pinYin{
            width: 100%;
            position: absolute;
            top: 10%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction:column;
          }
          &:nth-child(1){
            position: absolute;
            top: 60%;
            left: 23%;
          }
          &:nth-child(2){
            position: absolute;
            top: 15%;
            left: 36%;
          }
          &:nth-child(3){
            position: absolute;
            top: 37%;
            left: 54%;
          }
          &:nth-child(4){
            position: absolute;
            top: 70%;
            left: 70%;
          }
        }
        .notshow {
          opacity: 0;
        }
      }
    }
    .picture-mailbox {
      position: absolute;
      top: 73%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .content-picture-switching {
        width: 55%;
        display: flex;
        justify-content: center;
        align-items: center;
        .lesson-one {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          .xuXian{
            height: 20px;
            border-radius: 10%;
            border: 3px dashed #fff;
            position: relative;
          }
           img {
            display: inline-block;
            width: 100%;
          }
          .audioUrl{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top:-20px;
            .audioUrl_img{
              width: 40px;
              height: 40px;
              border-radius: 20px;
              background-color: #CD4C3F;
              
            }
          }
          .pinYin{
            width: 100%;
            position: absolute;
            top: 10%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction:column;
          }
          &:nth-child(1){
            position: relative;
            top: -500%;
          }
        }
      }
    }
  }
  .confettiCanvas {
    z-index: 900;
    position: absolute;
  }
  .drag-img-mailbox-4 {
    width: 80% !important;
  }
  .drag-img-envelope-4 {
    width: 60% !important;
  }
}
</style>
