<template>
  <div class="liten-then-choose-words-game-container layout-border">
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :isLastStep="isLastStep"
      @clickNext="nextPage"
    ></PageButton>
    <div class="game-content">
      <SceneSwitchThumbnails
        :buttons="buttonList"
        :numBers="numBers"
        @changeTheNumbers="changeTheNumbers"
      >
      </SceneSwitchThumbnails>
      <!-- page  select img-->
      <div class="choose-img-area">
        <div class="img-area">
          <div class="wenzi">
            <!-- <div class="trumpet-area" @click.stop="playPinyinAudio" v-throttle>
              <AudioPlayer :isPlay="isStartPlaying" :audioUrl="songUrl" />
            </div> -->
            <div class="trumpet-area-pinyin" v-for="(item, index) in allImgList" :key="index" v-if="currentIndex === index">
              <div>
                <div class="title-pinyin pinyin font-pinyin-medium">
                  {{ item.pinyin }}
                </div>
                <div class="title-hanzi font-hanzi-medium">
                  {{ item.hanzi }}
                </div>
              </div>
            </div>
          </div>
          <div
            class="checkGif"
            :class="{ checkGifWrong: !checkGif }"
            v-checkgif="checkGif"
            v-if="isShowCheckGif"
          ></div>
          <div class="img-content">
            <img
              v-for="(item, index) in allImgList[currentIndex].imgList"
              :src="item.bgImg"
              alt=""
              :key="index + 'img'"
              class="img-item"
              :class="{
                isCorrect:
                  item.id === answerList[currentIndex] &&
                  index === chooseImgIndex,
              }"
              @click="handleChooseImg(item.id, index)"
            />
          </div>
        </div>
      </div>
      <div class="title-area" v-if="titleInfo">
        <div class="title">
          <div class="title-pinyin pinyin font-pinyin-medium">
            {{ titleInfo.pinyin }}
          </div>
          <div class="title-hanzi font-hanzi-medium">
            {{ titleInfo.hanzi }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SceneSwitchThumbnails from "@/components/Course/CoursePage/StorySwitcher/sceneSwitchThumbnails.vue";
import AudioPlayer from "@/components/Course/GamePage/AudioPlayer";
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
import { startConfetti, playCorrectSound } from "@/utils/tools.js";
export default {
  props: {
    allImgList: {
      type: Array,
      require: true,
    },

    answerList: {
      type: Array,
      require: true,
    },

    titleInfo: {
      type: Object,
      require: false,
    },
    buttonList: {
      type: Array,
      require: true,
      default: () => [],
    },
    // wenziList: {
    //   type: Array,
    //   require: true,
    //   default: () => [],
    // },
  },
  components: {
    PageButton,
    SceneSwitchThumbnails,
    AudioPlayer,
  },

  data() {
    return {
      checkGif: true,
      isShowCheckGif: false,
      isStartPlaying: null,
      lockIndex: 0,
      insertIndex: 0,
      chooseImgIndex: null,
      numBers: 0,
      imgSocketInfo: {},
      songUrl: this.buttonList[0].audioSrc,
      currentIndex: 0,
      currentStarIndex: 0,
      isLastStep: false,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "look",
            startNumberRange: 0,
            endNumberRange: 100,
          },
        ],
      },
    };
  },
  created() {
    // startConfetti();
  },
  mounted() {
    this.$bus.$on("clickChoosedImg", ({ id, index }) => {
      this.handleChooseImg(id, index, true);
    });
    // this.$bus.$on("clickChoosedHanzi", ({ id, index }) => {
    //   this.handleChooseHanzi(id, index, true);
    // });
    this.$bus.$on("clickIntroAside", ({ index }) => {
      this.changeTheNumbers(index, true);
    });
  },
  beforeDestroy() {
    this.$bus.$off("clickChoosedImg");
    // this.$bus.$off("clickChoosedHanzi");
    this.$bus.$off("clickIntroAside");
  },
  watch: {
    //
    imgSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2003001,
          data: { value },
          text: "ListenThenChooseWordsGame点击socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  methods: {
    changeTheNumbers(index, isFromSocket = false) {
      if (!isFromSocket) {
        this.clickMenuSocketInfo = {
          index,
          value: Math.random(),
        };
      }
      this.numBers = index;
      this.currentIndex = index - 1;
      this.chooseImgIndex = null;
    },
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
    handleChooseImg(id, index, isFromSocket = false) {
      if (!isFromSocket) {
        this.imgSocketInfo = {
          id,
          index,
          value: Math.random(),
        };
      }
      // console.log(id);
      this.chooseImgIndex = index;
      if (id === this.answerList[this.currentIndex]) {
        this.isShowCheckGif = true;
        this.checkGif = true;
        playCorrectSound(true, false);
        setTimeout(() => {
          this.isShowCheckGif = false;
        }, 500);
        if (this.currentIndex === this.buttonList.length - 1) {
          startConfetti();
          playCorrectSound();
          setTimeout(() => {
              this.isLastStep = true;
          }, 500);
        }
      } else {
        this.isShowCheckGif = true;
        this.checkGif = false;
        playCorrectSound(false);
        setTimeout(() => {
          this.isShowCheckGif = false;
        }, 500);
      }
    },
    playPinyinAudio() {
      this.isStartPlaying = Math.random();

      this.songUrl = this.buttonList[this.currentIndex].audioSrc;
    },
    // checkToNext() {
    //   if (
    //     this.lockIndex >=
    //     this.allImgList.length
    //   ) {
    //     this.nextPart();
    //   }
    // },
    // nextPart() {
    //   setTimeout(() => {
    //     if (this.currentIndex >= this.allImgList.length) {
    //       this.currentIndex = this.allImgList.length - 1;
    //       startConfetti();
    //       playCorrectSound();
    //     } else {
    //       playCorrectSound(true, false);
    //     }
    //   }, 500);
    // },
  },
};
</script>

<style lang="scss" scoped>
.liten-then-choose-words-game-container {
  .game-content {
    width: 100%;
    height: 100%;
    position: relative;
    background: url("../../../../assets/img/16-GAMES/G65-stories-3-cards/level1-background.svg");
    border-radius: 58px;

    .choose-img-area {
      height: 80%;
      display: flex;
      justify-content: center;
      align-items: center;

      .img-area {
        // margin-left: 22%;
        margin-top: 10%;
        width: 70%;
        height: 100%;
        display: flex;
        flex-direction: column;
        // justify-content: center;
        align-items: center;
        background: #f7f4e0;
        border-radius: 58px;

        .wenzi {
          background: #224e96;
          border-radius: 58px 58px 0 0;
          width: 100%;
          height: 160px;
          color: white;
          display: flex;
          // flex-direction: column;
          justify-content: center;
          align-items: center;
          // position: relative;
          .trumpet-area {
            width: 100px;
            height: 100px;
            background-color: #cd4c3f;
            border-radius: 50%;
              &:hover {
                opacity: 0.9;
                cursor: pointer;
              }
            
          }
          .trumpet-area-pinyin{
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            margin-left: 8%;
            
          }
          .wenzi-content {
            // position: absolute;
            margin-left: 70px;
            // margin-top:30%;
            .hanzi {
              font-size: 32px;
            }
          }
        }
        .img-content {
          width: 70%;
          margin-top: 8%;
          // height: 80%;
          display: flex;
          justify-content: center;
          align-items: center;
          // flex-wrap: wrap;
          .img-item {
            width: 38%;
            margin-right: 2%;
            margin-bottom: 2%;
            display: inline-block;
            box-sizing: border-box;
            border: 10px solid #fff;
            cursor: pointer;
            &:active{
              border-color: #cd4c3f;
            }
          }
          // .active {
          //   border-color: #ce4c3f;
          // }
          // .isCorrect {
          //   border-color: #cd4c3f;
          // }
          .isCorrect {
            border-color: yellow;
          }
        }
      }
    }
  }
  .title-area {
    z-index: 11;
    position: absolute;
    left: 0;
    top: 10%;
    width: auto;
    height: auto;
    padding: 20px;
    background: #cd4c3f;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 22px;
    border-bottom-right-radius: 22px;
    color: #fff;
    .title {
      .title-pinyin,
      .title-hanzi {
        text-align: center;
      }
    }
  }
}
</style>
